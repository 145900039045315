body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
    max-width: 750px;
    margin: auto;
    text-align: center;
}

.keyword-input {
    min-width: 200px;
    padding-left: .5em;
}
.radio-group {
    background-color: #e7e7e7;
    color: black;
    margin: .5em 0;
    padding: 0 1em;
}
.radio-group-option {
    padding: .5em .75em;
    cursor: pointer;
    display: inline-block;
}
.radio-group-option.selected {
    background-color: darkblue;
    color: white;
}
.schedule-course {
    margin: 1em;
}
.schedule-course .desc {
    opacity: .6;
    margin: 0;
}
